<template>
  <div
    class="w-full h-screen bg-no-repeat bg-center bg-cover"
    :style="{ 'background-image': 'url(' + BackgroundImage + ')' }"
  ></div>

  <div
    class="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-primary-50 opacity-95 py-14 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="sm:mx-auto mb-14 sm:w-full sm:max-w-md">
          <img class="mx-auto h-12 w-auto" :src="logo" alt="Workflow" />
          <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">Sign in to your account</h2>
        </div>
        <form @submit.prevent="onSubmit" class="space-y-4">
          <BaseInput
            name="email"
            type="email"
            label="Email"
            :modelValue="email"
            @change="handleEmailChange"
            :error="errors.email"
          />
          <BaseInput
            name="password"
            type="password"
            label="Password"
            :modelValue="password"
            @change="handlePasswordChange"
            :error="errors.password"
          />
          <p v-if="errorMessage" class="mt-5 first-letter:capitalize text-failure-500 text-xs">
            {{ errorMessage }}
          </p>
          <BaseButton class="mt-10" fullWidth type="submit" label="Sign in" />
        </form>
        <router-link to="/password/reset">
          <p class="hover:text-gray-900 mt-6 text-center text-sm text-gray-500">Forgot password?</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'
import { isAdmin } from '../utils/utility_methods'
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup'
import { useRouter } from 'vue-router'

import { useUserStore } from '@/stores/user'
import BaseInput from '@/components/layout/BaseInput.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import BackgroundImage from '../assets/BG_blue.png'
import logo from '../assets/logo.png'

const errorMessage = ref('')
const userStore = useUserStore()

const router = useRouter()
const validationSchema = object({
  email: string().required().email(),
  password: string().required().min(8),
})

const { handleSubmit, errors } = useForm({
  validationSchema,
})

const { value: email, handleChange: handleEmailChange } = useField('email')
const { value: password, handleChange: handlePasswordChange } = useField('password')

const onSubmit = handleSubmit(async (values) => {
  try {
    const response = await axios.post('/users/sign_in', {
      user: values,
    })
    if (response.data.redirect_url && response.data.redirect_url !== '/admin/dashboard') {
      isAdmin.value = false
      await userStore.fetchNotifications()
      await userStore.fetchUserData()
      router.push('/')
    } else if (response.data.redirect_url) {
      isAdmin.value = true
      location.href = response.data.redirect_url
    }
  } catch (error) {
    console.log('Error :', error)
    errorMessage.value = error.response.data.message
  }
})
</script>
