<template>
  <Loader v-if="loading" />
  <div v-else class="w-50 overflow-hidden bg-primary-100 shadow sm:rounded-lg">
    <div
      class="flex pl-6 pt-6 items-center hover:cursor-pointer"
      @click="router.push(`/estimations/${route.params.id}/final-bid`)"
    >
      <ArrowLongLeftIcon class="h-5 w-5 mr-2" />
      <p class="text-sm hover:text-primary-700">Back to Proposal</p>
    </div>
    <div class="flex justify-center items-center my-5">
      <h3 class="my-16 text-3xl font-bold leading-6 text-gray-900">Proposal Details</h3>
    </div>
    <div class="my-5 px-5 border-gray-200 w-2/3 mx-auto">
      <div id="element-to-print">
        <div id="page2el"></div>
        <div class="page pb-12">
          <TitleHeader
            title="PROJECT PRICING TOTALS"
            :themeColor="proposalByIdQuery.result.value.proposal.pdfThemeColor"
          />
          <PricingTable
            :totalItems="[
              {
                name: 'Asphalt Services',
                total:
                  serviceTotals('Asphalt') +
                  markupTotals['Asphalt'] +
                  subcontractorTotal('Asphalt') +
                  serviceOverheadTotals['Asphalt'],
              },
              {
                name: 'Striping Services',
                total:
                  serviceTotals('Striping') +
                  markupTotals['Striping'] +
                  subcontractorTotal('Striping') +
                  serviceOverheadTotals['Striping'],
              },
              {
                name: 'Concrete Services',
                total:
                  serviceTotals('Concrete') +
                  markupTotals['Concrete'] +
                  subcontractorTotal('Concrete') +
                  serviceOverheadTotals['Concrete'],
              },
              {
                name: 'SealCoat Services',
                total:
                  serviceTotals('Seal Coating') +
                  markupTotals['Seal Coating'] +
                  subcontractorTotal('Seal Coating') +
                  serviceOverheadTotals['Seal Coating'],
              },
              {
                name: 'Grading Demo Services',
                total:
                  serviceTotals('Grading') +
                  markupTotals['Grading'] +
                  subcontractorTotal('Grading') +
                  serviceOverheadTotals['Grading'],
              },
            ]"
            :bidTotal="bidTotal"
          />
          <div>
            <div v-if="exclusions.length !== 0" class="sm:col-span-2 px-10 my-6">
              <dt class="text-md font-medium text-gray-900">PRE-EXCLUSIONS</dt>
              <div class="my-1 grid grid-cols-1 gap-x-4 gap-y-5 sm:grid-cols-3">
                <div v-for="item in exclusions" class="sm:col-span-1">
                  <li class="mt-1 text-sm text-gray-600">{{ item.exclusion.title }}</li>
                </div>
              </div>
            </div>
            <div v-if="inclusions.length !== 0" class="sm:col-span-2 px-10 my-6">
              <dt class="text-md font-medium text-gray-900">PRE-INCLUSIONS</dt>
              <div class="my-1 grid grid-cols-1 gap-x-4 gap-y-5 sm:grid-cols-3">
                <div v-for="item in inclusions" class="sm:col-span-1">
                  <li class="mt-1 text-sm text-gray-600">{{ item.inclusion.title }}</li>
                </div>
              </div>
            </div>
            <div
              v-if="proposalByIdQuery.result.value.proposal.notes"
              class="sm:col-span-2 px-10 my-6"
            >
              <dt class="text-md font-medium text-gray-900">Notes :</dt>
              <div class="my-1">
                <p class="text-sm whitespace-pre-line">
                  {{ proposalByIdQuery.result.value.proposal.notes }}
                </p>
              </div>
            </div>
            <div
              v-if="proposalByIdQuery.result.value.proposal.conditions"
              class="sm:col-span-2 px-10 my-6"
            >
              <dt class="text-md font-medium text-gray-900">Conditions :</dt>
              <div class="my-1 pb-8">
                <p class="text-sm whitespace-pre-line">
                  {{ proposalByIdQuery.result.value.proposal.conditions }}
                </p>
              </div>
            </div>
          </div>
          <PageFooter
            :number="proposalByIdQuery.result.value.proposal.number"
            :ownerData="companyDetails.result.value.organization.owner.nodes[0]"
            :themeColor="proposalByIdQuery.result.value.proposal.pdfThemeColor"
          />
        </div>
        <div
          v-if="proposalByIdQuery.result.value.proposal.writeupQuestions.nodes.length !== 0"
          class="page mt-16"
        >
          <div>
            <TitleHeader
              title="PROJECT SCOPE"
              :themeColor="proposalByIdQuery.result.value.proposal.pdfThemeColor"
            />
            <dl class="pb-40 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 px-2 py-10">
              <ServiceWriteups
                v-for="section in getWriteupSections.result.value.writeupSections.nodes.filter(
                  (section) =>
                    section.submittedQuestions.length !== 0 ||
                    hasSubmittedQuestions(section.children)
                )"
                :total="
                  serviceTotals(
                    section.name == 'New Construction'
                      ? 'Asphalt'
                      : section.name == 'Seal Coat'
                      ? 'Seal Coating'
                      : section.name
                  ) +
                  serviceOverheadTotals[
                    section.name == 'New Construction'
                      ? 'Asphalt'
                      : section.name == 'Seal Coat'
                      ? 'Seal Coating'
                      : section.name
                  ] +
                  markupTotals[
                    section.name == 'New Construction'
                      ? 'Asphalt'
                      : section.name == 'Seal Coat'
                      ? 'Seal Coating'
                      : section.name
                  ] +
                  subcontractorTotal(
                    section.name == 'New Construction'
                      ? 'Asphalt'
                      : section.name == 'Seal Coat'
                      ? 'Seal Coating'
                      : section.name
                  )
                "
                :questions="section.submittedQuestions"
                :subsection="
                  section.children.map((child) => ({
                    title: child.name,
                    questions: child.submittedQuestions,
                  }))
                "
                :service="section.name"
                :validDate="proposalByIdQuery.result.value.proposal.goodThrough"
                @refetch-writeup-data="getWriteupSections.refetch()"
              />
            </dl>
            <PageFooter
              :number="proposalByIdQuery.result.value.proposal.number"
              :ownerData="companyDetails.result.value.organization.owner.nodes[0]"
              :themeColor="proposalByIdQuery.result.value.proposal.pdfThemeColor"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-10 pb-20">
      <button
        @click="generatePDF"
        type="button"
        class="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Generate Proposal
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useRoute, useRouter } from 'vue-router'

import Loader from '@/components/layout/Loader.vue'
import TitleHeader from '@/components/layout/PDFComponents/TitleHeader.vue'
import PageFooter from '@/components/layout/PDFComponents/PageFooter.vue'
import PricingTable from '@/components/layout/PDFComponents/PricingTable.vue'
import ServiceWriteups from '@/components/layout/PDFComponents/ServiceWriteups.vue'
import StripingService from '@/components/layout/PDFComponents/StripingService.vue'
import { finalBidTab } from '@/keys/constants'

import GET_PROPOSAL_BY_ID from '../graphql/queries/getProposalById.gql'
import GET_STRIPING_CATEGORIES from '../graphql/queries/getStripingCategories.gql'
import GET_WRITEUP_SECTIONS from '../graphql/queries/getWriteupSections.gql'
import MARKUP_COST_CALCULATE from '../graphql/mutations/calculations/markupCostCalculate.gql'
import GET_COMPANY_DATA from '../graphql/queries/getCompanyData.gql'
import { ArrowLongLeftIcon } from '@heroicons/vue/20/solid'

const route = useRoute()
const router = useRouter()
const stripingData = ref({})
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const companyDetails = useQuery(GET_COMPANY_DATA)

const stripingCategories = useQuery(GET_STRIPING_CATEGORIES)
const getWriteupSections = useQuery(GET_WRITEUP_SECTIONS, {
  proposalId: route.params.id,
})
const calculateMarkupCost = useMutation(MARKUP_COST_CALCULATE)

const writeupQuestions = ref([])
const markupTotals = ref({})
const overheadCost = ref(0)
const serviceOverheadTotals = ref({})

const overheadTotals = computed(
  () =>
    workTypeTotals('equipmentTotal') +
    workTypeTotals('laborerTotal') +
    workTypeTotals('miscellaneousCostTotal') +
    stripingTotal.value
)

const bidTotal = computed(
  () =>
    serviceTotals('Asphalt') +
    markupTotals.value['Asphalt'] +
    subcontractorTotal('Asphalt') +
    serviceTotals('Striping') +
    markupTotals.value['Striping'] +
    subcontractorTotal('Striping') +
    serviceTotals('Concrete') +
    markupTotals.value['Concrete'] +
    subcontractorTotal('Concrete') +
    serviceTotals('Grading') +
    markupTotals.value['Grading'] +
    subcontractorTotal('Grading') +
    serviceTotals('Seal Coating') +
    markupTotals.value['Seal Coating'] +
    subcontractorTotal('Seal Coating') +
    overheadCost.value
)

const inclusions = computed(() => proposalByIdQuery.result.value.proposal.inclusions.nodes)
const exclusions = computed(() => proposalByIdQuery.result.value.proposal.exclusions.nodes)
const loading = computed(() => {
  return (
    proposalByIdQuery.loading.value ||
    stripingCategories.loading.value ||
    getWriteupSections.loading.value ||
    companyDetails.loading.value
  )
})

onMounted(async () => {
  await companyDetails.refetch()
})

watch(
  [proposalByIdQuery.result, stripingCategories.result, companyDetails.result],
  async (value) => {
    if (value[0] && value[1] && value[2]) {
      proposalByIdQuery.result.value.proposal.proposalsServices.nodes.forEach(
        async (proposalService) => {
          let sum = 0
          let serviceTotal = proposalByIdQuery.result.value.proposal.serviceTotals.nodes.filter(
            (total) => total.serviceName == proposalService.service.name
          )[0]
          serviceTotal = {
            ...serviceTotal,
            subcontractorTotal: subcontractorTotal(proposalService.service.name),
            totals: serviceTotals(proposalService.service.name),
          }
          for (const item of finalBidTab[proposalService.service.name.replace(/\s+/g, '')]) {
            if (proposalService[item.markup] && serviceTotal[item.total]) {
              await calculateMarkupCost
                .mutate({
                  input: {
                    markupPercentage: parseFloat(proposalService[item.markup]),
                    subTotal:
                      item.markup == 'totalMarkup'
                        ? serviceTotal[item.total] + serviceTotal['subcontractorTotal']
                        : serviceTotal[item.total],
                  },
                })
                .then((result) => {
                  sum += result.data.markupCostCalculate.markupCost
                })
            }
          }
          markupTotals.value[proposalService.service.name] = sum
          serviceOverheadTotals.value[proposalService.service.name] = await overheadTotal(
            proposalService.service.name
          )
        }
      )

      writeupQuestions.value = value[0].proposal.writeupQuestions.nodes.map((item) => item)
      value[1].stripingCategories.nodes.forEach((categoryItem) => {
        stripingData.value[camelCaseString(categoryItem.name)] =
          proposalByIdQuery.result.value.proposal.constructionMaterial.nodes.filter(
            (item) => item.workType.workTypeCategory?.name == categoryItem.name
          )
      })
    }
    if (value[2] && value[2].organization.generalAdminCost) {
      const cost = await calculateMarkupCost.mutate({
        input: {
          markupPercentage: value[2].organization.generalAdminCost,
          subTotal: overheadTotals.value,
        },
      })
      overheadCost.value = cost.data.markupCostCalculate.markupCost
    }
  }
)

const generatePDF = () => {
  window.open(`/reports/${proposalByIdQuery.result.value.proposal.id}.pdf`, '_blank')
}

const serviceTotals = (serviceName) => {
  let sum = 0
  proposalByIdQuery.result.value.proposal.serviceTotals.nodes
    .filter((item) => item.serviceName === serviceName)
    .forEach(
      (element) =>
        (sum += Object.entries(element)
          .filter(
            ([key, value]) =>
              typeof value === 'number' &&
              !(
                element['serviceName'] === 'Grading' &&
                (key === 'gradingEquipment' || key === 'gradingLabor')
              )
          )
          .reduce((innerSum, [key, value]) => innerSum + value, 0))
    )
  return sum
}

const subcontractorTotal = (service) => {
  return proposalByIdQuery.result.value.proposal.subcontractors.nodes
    .filter(
      (itemService) =>
        itemService.proposalsServicesItems[0].proposalsService.service.name == service
    )
    .reduce((total, item) => total + item.cost, 0)
}

function convertToTitleCase(str) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const camelCaseString = (string) =>
  string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())

const showStriping = computed(() =>
  Object.values(stripingData.value).some((arr) => Array.isArray(arr) && arr.length !== 0)
)

const workTypeTotals = (name) => {
  return proposalByIdQuery.result.value.proposal.serviceTotals.nodes.reduce(
    (accumulator, object) => {
      if (name === 'equipmentTotal' && object['serviceName'] !== 'Grading') {
        return accumulator + object[name] + object['gradingEquipment']
      } else if (name === 'laborerTotal' && object['serviceName'] !== 'Grading') {
        return accumulator + object[name] + object['gradingLabor']
      } else return accumulator + object[name]
    },
    0
  )
}

const overheadTotal = async (service) => {
  const overheadTotal = proposalByIdQuery.result.value.proposal.serviceTotals.nodes
    .filter((item) => item.serviceName === service)
    .reduce((acc, curr) => {
      acc += curr.equipmentTotal + curr.laborerTotal + curr.miscellaneousCostTotal

      if (service !== 'Grading') {
        acc += curr.gradingLabor + curr.gradingEquipment
      }
      if (service === 'Striping') {
        acc += curr.constructionTotal
      }
      return acc
    }, 0)
  const cost = await calculateMarkupCost.mutate({
    input: {
      markupPercentage: companyDetails.result.value.organization.generalAdminCost,
      subTotal: overheadTotal,
    },
  })
  return cost.data.markupCostCalculate.markupCost
}

const stripingTotal = computed(
  () =>
    proposalByIdQuery.result.value.proposal.serviceTotals.nodes.filter(
      (element) => element.serviceName == 'Striping'
    )[0]?.constructionTotal
)

const hasSubmittedQuestions = (array) => {
  return array.some((item) => item.submittedQuestions.length > 0)
}
</script>

<style>
.page {
  min-height: 1055px;
  position: relative;
  background-color: #fff;
  page-break-inside: avoid;
}

.avoid {
  page-break-inside: avoid;
}
.page-header-container {
  position: relative;
  height: 6rem;
}

.page-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #07275c;
  color: #fff;
  height: 4rem;
  border: 1px hidden black;
}

.page-header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-left: 6rem;
  text-align: center;
  background: #fff;
  border: 1px lightgray;
  border-style: solid hidden;
  color: darkblue;
  width: 30rem;
  height: 4rem;
  font-size: 20px;
  font-weight: bold;
}

.footer-container {
  background-color: #07275c;
}
</style>
