<template>
  <div
    class="w-full h-screen bg-no-repeat bg-center bg-cover"
    :style="{ 'background-image': 'url(' + BackgroundImage + ')' }"
  ></div>

  <div
    class="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-primary-50 opacity-95 py-14 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="sm:mx-auto mb-14 sm:w-full sm:max-w-md">
          <img class="mx-auto h-12 w-auto" :src="logo" alt="Workflow" />
          <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">
            SignUp to create account
          </h2>
        </div>
        <form @submit.prevent="onSubmit" class="space-y-4">
          <BaseInput
            name="password"
            type="password"
            label="Password"
            :modelValue="password"
            @change="handlePasswordChange"
            :error="errors.password"
          />
          <BaseInput
            name="password_confirmation"
            type="password"
            label="Confirm Password"
            :modelValue="password_confirmation"
            @change="handleConfirmPasswordChange"
            :error="errors.password_confirmation"
          />
          <p v-if="errorMessage" class="mt-5 first-letter:capitalize text-failure-500 text-xs">
            {{ errorMessage }}
          </p>
          <BaseButton class="mt-10" fullWidth type="submit" label="Sign up" />
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'

import BaseInput from '@/components/layout/BaseInput.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import BackgroundImage from '../assets/BG_blue.png'
import logo from '../assets/logo.png'

const errorMessage = ref('')
const route = useRoute()

const router = useRouter()
const validationSchema = object({
  password: string().required().min(12),
  password_confirmation: string().required().min(12),
})

const { handleSubmit, errors } = useForm({
  validationSchema,
})

const { value: password, handleChange: handlePasswordChange } = useField('password')
const { value: password_confirmation, handleChange: handleConfirmPasswordChange } =
  useField('password_confirmation')

const onSubmit = handleSubmit(async (values) => {
  const invitationToken = route.query.invitation_token || null

  try {
    await axios.put('/users/invitation', {
      user: {
        invitation_token: invitationToken,
        ...values,
      },
    })
    router.push('/login')
  } catch (error) {
    console.log('Error :', error)
    errorMessage.value = error.response.data.message
  }
})
</script>
