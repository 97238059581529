<template>
  <Loader v-if="loader" />
  <div v-else>
    <Toast v-if="toast.showToast" :title="toast.title" />
    <Modal
      :open="showModal"
      :title="newStripingWorks ? 'New Striping Work ' : editModal?.item?.name"
    >
      <form @submit="onSubmit">
        <div class="grid grid-cols-2 gap-4 my-10">
          <div class="flex">
            <input
              :checked="doubleCoatEnabled"
              v-model="doubleCoatEnabled"
              v-bind="doubleCoatEnabledAttrs"
              type="checkbox"
              class="h-5 w-5 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
            />
            <label for="doubleCoatEnabled" class="px-2 block text-sm font-medium text-gray-700"
              >Double Coat Enabled
            </label>
          </div>
          <div class="flex">
            <input
              :checked="newLayoutEnabled"
              v-model="newLayoutEnabled"
              v-bind="newLayoutEnabledAttrs"
              type="checkbox"
              class="h-5 w-5 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
            />
            <label for="newLayoutEnabled" class="px-2 block text-sm font-medium text-gray-700"
              >New Layout Enabled
            </label>
          </div>
          <div class="col-span-1 w-60">
            <BaseAutocomplete
              label="Select Worktype Category"
              v-bind="workTypeCategoryIdAttrs"
              :searchArray="worktypeCategoryDataQuery.result.value.stripingCategories.nodes"
              @update:modelValue="worktypeCategoryUpdate($event)"
              :roundedInput="false"
              :selectedItem="editModal.item ? editModal.item.workType.workTypeCategory : ''"
            />
            <p
              v-if="errors.workTypeCategoryId && meta.touched"
              class="mt-1 first-letter:capitalize text-failure-500 text-xs"
            >
              {{ errors.workTypeCategoryId }}
            </p>
          </div>
          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700">Name </label>
            <div class="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
              <input
                type="text"
                v-model="name"
                v-bind="nameAttrs"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Name"
              />
            </div>
            <p v-if="errors.name" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.name }}
            </p>
          </div>
          <div class="w-60">
            <label for="newWorkCost" class="block text-sm font-medium text-gray-700"
              >Restripe Cost
            </label>
            <div class="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
              <input
                type="number"
                v-model.number="newWorkCost"
                v-bind="newWorkCostAttrs"
                step="0.01"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="0"
              />
            </div>
            <p
              v-if="errors.newWorkCost"
              class="mt-1 first-letter:capitalize text-failure-500 text-xs"
            >
              {{ errors.newWorkCost }}
            </p>
          </div>
          <div class="col-span-2" v-show="createStripingWorkError">
            <p class="mt-2 first-letter:capitalize text-failure-500 text-xs">
              {{ createStripingWorkError }}
            </p>
          </div>
        </div>

        <div class="flex justify-end mx-5 py-4">
          <button
            type="button"
            class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
            @click="handleCancel"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
    <div class="flex my-4 px-1 flex-row justify-between items-center">
      <h1 class="text-2xl font-semibold text-gray-900">Striping Works</h1>
      <BaseButton type="button" @click="handleEdit(false)" label="New Striping" size="small" />
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-primary-50">
                <tr>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Worktype
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Restripe Cost
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    New Layout Enabled
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Double Coat Enabled
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    New Layout Additional Cost
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Double Coat Additional Cost
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Category
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="stripingWork in stripingDataQuery.result.value.stripingWorks.nodes"
                  :key="stripingWork.id"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ stripingWork.name }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    {{ stripingWork.newWorkCost ?? '---' }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    {{ stripingWork.newLayoutEnabled ? 'Yes' : 'No' }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    {{ stripingWork.doubleCoatEnabled ? 'Yes' : 'No' }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    {{ stripingWork.newLayoutAdditionalCost ?? '---' }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    {{ stripingWork.doubleCoatAdditionalCost ?? '---' }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    {{
                      stripingWork?.workType?.workTypeCategory
                        ? stripingWork.workType.workTypeCategory.name
                        : '---'
                    }}
                  </td>

                  <td class="relative whitespace-nowrap py-4 text-sm font-medium">
                    <div class="flex">
                      <div class="px-1">
                        <PencilSquareIcon
                          @click="handleEdit(stripingWork)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="px-1">
                        <TrashIcon
                          @click="handleDelete(stripingWork)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/20/solid'
import { useForm } from 'vee-validate'
import * as yup from 'yup'

import Loader from '@/components/layout/Loader.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import BaseAutocomplete from '@/components/layout/BaseAutocomplete.vue'
import Modal from '@/components/layout/Modal.vue'
import Toast from '@/components/layout/Toast.vue'
import GET_STRIPING_DATA from '../../graphql/queries/getStripingData.gql'
import GET_WORKTYPES_CATEGORY_DATA from '../../graphql/queries/getStripingCategories.gql'
import UPDATE_STRIPING_WORKS from '../../graphql/mutations/admin/updateStripingWorks.gql'
import CREATE_STRIPING_WORKS from '../../graphql/mutations/admin/createStripingWorks.gql'
import DELETE_STRIPING_WORKS from '../../graphql/mutations/admin/deleteStripingWorks.gql'

const showModal = ref(false)
const newStripingWorks = ref(false)
const editModal = ref({ open: false, item: null })
const toast = ref({ showToast: false, title: '' })
const createStripingWorkError = ref(null)

const updateStripingWorks = useMutation(UPDATE_STRIPING_WORKS)
const { mutate: createStripingWorks, error: createStripingWorksError } =
  useMutation(CREATE_STRIPING_WORKS)
const deleteStripingWorks = useMutation(DELETE_STRIPING_WORKS)

const stripingDataQuery = useQuery(GET_STRIPING_DATA)
const worktypeCategoryDataQuery = useQuery(GET_WORKTYPES_CATEGORY_DATA)

// Define schemas
const baseSchema = yup.object().shape({
  newWorkCost: yup.number().required('Restripe Cost is required'),
  name: yup.string().required('Name is required'),
}) // No validation for base case
const validationSchemaWithWorkTypeCategoryId = yup.object().shape({
  workTypeCategoryId: yup.string().required('Worktype is required'),
})

// Computed schema based on newStripingWorks
const currentSchema = computed(() => {
  return newStripingWorks.value ? validationSchemaWithWorkTypeCategoryId : baseSchema
})

const {
  values,
  errors,
  handleSubmit,
  setFieldValue,
  defineField,
  setFieldError,
  setValues,
  meta,
  handleReset,
} = useForm({
  validationSchema: currentSchema.value,
  initialValues: {
    workTypeCategoryId: null,
  },
})

watch(newStripingWorks, (newValue) => {
  if (newValue) {
    if (!values.workTypeCategoryId) {
      setFieldError('workTypeCategoryId', 'Worktype Category is required')
    }
  }
})

const [name, nameAttrs] = defineField('name')
const [newWorkCost, newWorkCostAttrs] = defineField('newWorkCost')
const [newLayoutEnabled, newLayoutEnabledAttrs] = defineField('newLayoutEnabled')
const [doubleCoatEnabled, doubleCoatEnabledAttrs] = defineField('doubleCoatEnabled')
const [workTypeCategoryId, workTypeCategoryIdAttrs] = defineField('workTypeCategoryId')

const worktypeCategoryUpdate = (worktypeCategory) => {
  setFieldValue('workTypeCategoryId', worktypeCategory.id)
  createStripingWorkError.value = null
}

const loader = computed(
  () => stripingDataQuery.loading.value || worktypeCategoryDataQuery.loading.value
)

const handleCancel = () => {
  showModal.value = false
  createStripingWorkError.value = null
  handleReset()
}

const onSubmit = handleSubmit(async (values) => {
  if (newStripingWorks.value) {
    const response = await createStripingWorks({
      input: values,
    })
    if (response.data.stripingWorkCreate.errors.length) {
      createStripingWorkError.value = response.data.stripingWorkCreate.errors[0]
    } else {
      toast.value = { showToast: true, title: 'Striping Works created Successfully!' }
      createStripingWorkError.value = null
      newStripingWorks.value = false
      showModal.value = false
      handleReset()
      await stripingDataQuery.refetch()
    }
  } else {
    if (editModal.value.open) {
      const payload = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => value !== null)
      )
      await updateStripingWorks.mutate({
        input: {
          input: payload,
        },
      })
      toast.value = { showToast: true, title: 'Striping Works updated Successfully!' }
      showModal.value = false
      await stripingDataQuery.refetch()
    }
  }
})

const handleEdit = (item) => {
  editModal.value.open = true
  if (!item) {
    newStripingWorks.value = true
  } else {
    editModal.value.item = item
    newStripingWorks.value = false
    setValues({
      id: item.id,
      name: item.name,
      newWorkCost: item.newWorkCost,
      newLayoutEnabled: item.newLayoutEnabled,
      doubleCoatEnabled: item.doubleCoatEnabled,
      workTypeCategoryId: item.workType?.workTypeCategory?.id,
    })
  }
  showModal.value = true
}

const handleDelete = async (item) => {
  await deleteStripingWorks.mutate({ input: { id: item.id } })
  toast.value = { showToast: true, title: 'Striping Works removed Successfully!' }
  await stripingDataQuery.refetch()
}
</script>
