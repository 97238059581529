import { ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/helpers/apolloClient.js'

import { markupCost } from '../utils/utility_methods'
import UPDATE_EQUIPMENT from '../graphql/mutations/updateProposalEquipment.gql'
import UPDATE_LABORER from '../graphql/mutations/updateProposalLaborer.gql'
import UPDATE_MISCELLANEOUS_COST from '../graphql/mutations/updateProposalMiscellaneousCost.gql'
import UPDATE_SUBCONTRACTOR from '../graphql/mutations/updateProposalSubcontractor.gql'
import UPDATE_WORK from '../graphql/mutations/updateProposalWork.gql'
import UPDATE_WORK_PROPERTY from '../graphql/mutations/updateProposalWorkProperties.gql'
import UPDATE_LCH_WORK from '../graphql/mutations/updateProposalLchWork.gql'
import UPDATE_PROPOSAL_SERVICE from '../graphql/mutations/updateProposalsServicesItem.gql'
import UPDATE_NOTE from '../graphql/mutations/updateNote.gql'
import UPDATE_OPTION from '../graphql/mutations/updateOption.gql'

provideApolloClient(apolloClient)
const responseMessage = ref(null)

const proposalUpdateEquipment = useMutation(UPDATE_EQUIPMENT)
const proposalUpdateLaborer = useMutation(UPDATE_LABORER)
const proposalUpdateMiscellaneousCost = useMutation(UPDATE_MISCELLANEOUS_COST)
const proposalUpdateSubcontractor = useMutation(UPDATE_SUBCONTRACTOR)
const proposalUpdateWork = useMutation(UPDATE_WORK)
const proposalUpdateWorkProperties = useMutation(UPDATE_WORK_PROPERTY)
const proposalUpdateLchWork = useMutation(UPDATE_LCH_WORK)
const proposalUpdateService = useMutation(UPDATE_PROPOSAL_SERVICE)
const updateNote = useMutation(UPDATE_NOTE)
const updateOption = useMutation(UPDATE_OPTION)

export async function useEditTableData(name, payload, rowData, proposalId) {
  let responseData = ref(await editMutation(name, payload, rowData, proposalId))
  return { responseData, responseMessage }
}

const editMutation = async (name, payload, rowData) => {
  if (name == 'equipmentData' || name == 'gradingEquipmentData') {
    const payloadArray = payload.reduce((acc, item) => {
      if (item.bulkEdit === true || item.bulkEdit === undefined) {
        acc.push({
          id: item.id,
          name: item.name,
          hoursPerDay: parseInt(item.hoursPerDay),
          quantity: parseInt(item.quantity),
          totalDaysOnJob: parseInt(item.totalDaysOnJob),
        })
      }
      return acc
    }, [])
    responseMessage.value = { showToast: true, title: 'Equipment updated successfully!' }
    return await proposalUpdateEquipment.mutate({
      input: { input: payloadArray },
    })
  } else if (name == 'laborerData' || name == 'gradingLaborerData') {
    const payloadArray = payload.reduce((acc, item) => {
      if (item.bulkEdit === true || item.bulkEdit === undefined) {
        acc.push({
          id: item.id,
          name: item.name,
          quantity: parseInt(item.quantity),
          hoursOnSite: parseInt(item.hoursOnSite),
          daysOnSite: parseInt(item.daysOnSite),
          totalRegularPay: item.totalRegularPay,
          totalOvertimePay: item.totalOvertimePay,
        })
      }
      return acc
    }, [])
    responseMessage.value = { showToast: true, title: 'Laborer updated successfully!' }
    return await proposalUpdateLaborer.mutate({
      input: {
        input: payloadArray,
      },
    })
  } else if (name == 'miscellaneousCostData') {
    const payloadArray = payload.reduce((acc, item) => {
      if (item.bulkEdit === true || item.bulkEdit === undefined) {
        acc.push({
          id: item.id,
          name: item.name,
          costPerUnit: parseInt(item.costPerUnit),
          quantity: parseInt(item.quantity),
          unitOfMeasurement: item.unitOfMeasurement,
          addStripingOnProposal: item.addStripingOnProposal,
        })
      }
      return acc
    }, [])
    responseMessage.value = { showToast: true, title: 'MiscellaneousCost updated successfully!' }
    return await proposalUpdateMiscellaneousCost.mutate({
      input: { input: payloadArray },
    })
  } else if (name == 'subcontractorsData') {
    const cost = await markupCost(parseFloat(rowData[0].markup), parseFloat(rowData[0].cost))
    if (payload.service) {
      await proposalUpdateService.mutate({
        input: {
          input: {
            id: rowData[0].proposalsServicesItems[0].id,
            proposalsServiceId: payload.service.id,
          },
        },
      })
    }
    responseMessage.value = { showToast: true, title: 'Subcontractor updated successfully!' }
    return await proposalUpdateSubcontractor.mutate({
      input: {
        input: {
          id: payload.id,
          company: payload.company,
          workDescription: payload.workDescription,
          quoteNumber: JSON.stringify(payload.quoteNumber),
          cost: cost.data.markupCostCalculate.totalCost,
          bonds: payload.bonds,
          markup: payload.markup,
        },
      },
    })
  } else if (name == 'constructionMaterialsData') {
    await Promise.all(
      payload.property.map(async (item) => {
        const updatePropertyResponse = await proposalUpdateWorkProperties.mutate({
          input: { input: item },
        })
        await proposalUpdateWork.mutate({
          input: {
            input: {
              id: updatePropertyResponse.data.proposalUpdateWorkProperties.proposalsWorkProperty
                .proposalsServicesWork.id,
              quantity: parseFloat(payload.quantity),
              costPerUnit: payload.costPerUnit,
            },
          },
        })
      })
    )
    responseMessage.value = { showToast: true, title: 'Material updated successfully!' }
  } else if (name == 'haulAndDumpData') {
    responseMessage.value = { showToast: true, title: 'Material updated successfully!' }
    return await proposalUpdateLchWork.mutate({
      input: {
        input: {
          id: rowData.id,
          area: parseFloat(payload.area),
          depth: parseFloat(payload.depth),
          loadCutHaulTime: parseFloat(payload.loadCutHaulTime),
          costTrucking: parseFloat(payload.costTrucking),
          costDump: parseFloat(payload.costDump),
        },
      },
    })
  } else if (name == 'optionsData') {
    responseMessage.value = { showToast: true, title: 'Option updated successfully!' }
    return await Promise.all(
      rowData.map(async (row) => {
        await updateNote.mutate({
          input: { input: { id: row.note.id, summary: payload.note.summary } },
        })
        return await updateOption.mutate({
          input: {
            input: {
              id: row.id,
              name: payload.name,
              estimatedCost: parseFloat(payload.estimatedCost),
            },
          },
        })
      })
    )
  } else {
    const payloadArray = payload.reduce((acc, item) => {
      if (item.bulkEdit === true || item.bulkEdit === undefined) {
        acc.push({
          id: item.id,
          name: item.name,
          quantity: parseFloat(item.quantity),
          costPerUnit: parseFloat(item.costPerUnit),
          newLayoutSelected: item.newLayoutSelected,
          doubleCoatSelected: item.doubleCoatSelected,
        })
      }
      return acc
    }, [])
    responseMessage.value = { showToast: true, title: 'Material updated successfully!' }
    return await proposalUpdateWork.mutate({
      input: {
        input: payloadArray,
      },
    })
  }
}
