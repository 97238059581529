<template>
  <main>
    <div>
      <Loader v-if="loading" />
      <div v-else class="grid grid-cols-4 gap-x-8 justify-evenly">
        <div class="col-span-2 py-8">
          <div class="pb-6 overflow-hidden bg-white shadow rounded-lg">
            <div class="bg-primary-700 text-white justify-between px-5 py-3">
              <h1 class="text-xl font-semibold py-3.5">Notifications</h1>
            </div>
            <div class="px-6 h-[53vh] pt-6 overflow-x-auto">
              <div
                v-show="store.notifications?.nodes.length == 0"
                class="pt-44 relative flex justify-center items-center"
              >
                <p class="px-2 text-xl text-gray-700">No Notifications</p>
              </div>
              <ul role="list" class="divide-y divide-gray-100">
                <li
                  v-for="item in store.notifications?.nodes"
                  :key="item.commit"
                  class="py-4 hover:bg-primary-50 cursor-pointer"
                >
                  <router-link
                    :to="item.redirectUrl ? item.redirectUrl : '/'"
                    @click="handleUpdateNotification(item)"
                  >
                    <div class="flex items-center gap-x-3">
                      <BellAlertIcon class="h-10 w-10 text-primary-600" />
                      <h3 class="flex-auto text-sm leading-6 text-gray-900">
                        {{ item.message }}
                      </h3>
                      <div class="flex flex-col items-center">
                        <div
                          v-show="!item.readAt"
                          class="text-primary-600 bg-blue-500/10 h-2.5 w-2.5 rounded-full bg-current my-1"
                        />

                        <time
                          :datetime="item.dateTime"
                          class="text-center text-xs text-gray-500 w-24"
                        >
                          {{ notificationTime(item.createdAt) }}
                        </time>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ListItem class="col-span-2" :items="proposalsData" />
      </div>
    </div>
  </main>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable'
import { computed, onMounted, ref, watch } from 'vue'
import { useMutation } from '@vue/apollo-composable'

import GET_PROPOSALS from '../graphql/queries/getProposals.gql'
import GET_PROPOSAL_COUNT from '../graphql/queries/getProposalCount.gql'
import UPDATE_NOTIFICATION from '../graphql/mutations/updateNotification.gql'
import ListItem from '@/components/layout/ListItem.vue'
import Loader from '@/components/layout/Loader.vue'
import { useUserStore } from '../stores/user'
import { BellAlertIcon } from '@heroicons/vue/20/solid'
import { notificationTime } from '../utils/utility_methods'

const updateNotification = useMutation(UPDATE_NOTIFICATION)
const store = useUserStore()
const notifications = ref([])

onMounted(() => {
  setTimeout(() => {
    throw new Error('Test error1 for Bugsnag')
  }, 6000)
})

watch(
  () => store.notifications,
  (data) => {
    notifications.value = data?.nodes || []
  }
)

const proposals = useQuery(GET_PROPOSALS)
const proposalsCount = useQuery(GET_PROPOSAL_COUNT)

const loading = computed(() => proposals.loading.value || proposalsCount.loading.value)

const proposalsData = computed(() => proposals.result.value?.proposals?.proposals.nodes ?? [])

const handleUpdateNotification = async (notification) => {
  if (!notification.readAt) {
    await updateNotification.mutate({
      input: { notification: { id: notification.id, readAt: new Date().toISOString() } },
    })
    await store.fetchNotifications()
  }
}
</script>
