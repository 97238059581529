<template>
  <div>
    <Modal
      v-if="inputObject.length !== 0"
      :open="showModal"
      :title="`${title} Construction Material`"
    >
      <nav class="scroll" aria-label="Progress">
        <ol role="list" class="space-y-4 md:flex md:space-y-0 md:space-x-8">
          <li v-for="step in selectedData" :key="step.name" class="md:flex-1">
            <a
              v-if="step.status === 'complete'"
              :href="step.href"
              class="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
            >
              <span class="text-xs font-medium text-primary-600 group-hover:text-primary-800">{{
                step.name ? step.name : step.workType.name
              }}</span>
            </a>
            <a
              v-else-if="step.status === 'current'"
              :href="step.href"
              class="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
              aria-current="step"
            >
              <span class="text-xs font-medium">{{
                step.name ? step.name : step.workType.name
              }}</span>
            </a>
            <a
              v-else
              :href="step.href"
              class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
            >
              <span class="text-xs font-medium text-gray-500 group-hover:text-gray-700">{{
                step.name ? step.name : step.workType.name
              }}</span>
            </a>
          </li>
        </ol>
      </nav>
      <div>
        <div
          v-for="(option, selectedOptionsIndex) in selectedData"
          v-show="selectedOptionsIndex === step"
          class="grid grid-cols-2 gap-4 my-10"
        >
          <div v-if="editModal && hasEmptyInput" class="flex col-span-2">
            <div class="flex-shrink-0">
              <InformationCircleIcon class="h-5 w-5 text-failure-600" aria-hidden="true" />
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm text-failure-600">Property values are missing</p>
            </div>
          </div>
          <div class="col-span-2" v-if="option.properties.length == 0">
            <p>No properties for this material</p>
          </div>
          <div
            v-else
            v-for="(item, index) in option.properties"
            class="col-span-2 grid grid-cols-2 gap-4"
          >
            <div v-if="item.name == 'smr'" class="w-60">
              <label for="name" class="block text-sm font-medium text-gray-700"
                >smooth medium rough
              </label>
              <select
                class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                @change="handleChange($event, true, selectedOptionsIndex)"
                :value="
                  inputObject[selectedOptionsIndex].properties.find((item) => item.name == 'smr')
                    .value
                "
              >
                <option v-for="option in ['s', 'm', 'r']" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div v-else-if="item.name == 'number of coats'" class="w-60">
              <label for="name" class="block text-sm font-medium text-gray-700"
                >{{ item.name }}
              </label>
              <select
                v-model="inputObject[selectedOptionsIndex].properties[index].value"
                class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                @change="handleChange($event, false, selectedOptionsIndex)"
              >
                <option v-for="option in ['1', '2']" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div v-else class="w-60">
              <label for="name" class="block text-sm font-medium text-gray-700"
                >{{ item.name }}
              </label>
              <div class="relative mt-1">
                <input
                  :id="item.id"
                  :type="item.type ?? item?.workTypeProperty?.type ?? 'text'"
                  :name="item.name"
                  :placeholder="`Enter ${item.name}`"
                  v-model="inputObject[selectedOptionsIndex].properties[index].value"
                  @input="handleChange($event, false, selectedOptionsIndex)"
                  @keyup="formatWithCommas(selectedOptionsIndex, index)"
                  class="peer block w-full border-0 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <div
                  class="absolute inset-x-0 bottom-0 border-t peer-focus:border-t-2 peer-focus:border-indigo-600"
                  :class="
                    editModal &&
                    !item.isComplete &&
                    !inputObject[selectedOptionsIndex].properties[index].value
                      ? 'border-red-600 '
                      : 'border-gray-300'
                  "
                  aria-hidden="true"
                />
              </div>
            </div>
            <div v-show="item.name !== 'smr'" class="w-60 flex items-center">
              <p class="block text-sm font-medium text-gray-600">
                Unit of Measurement :
                <span class="font-bold">{{ item?.unitOfMeasurement }}</span>
              </p>
            </div>
          </div>

          <div
            v-if="!editModal && option.material"
            v-for="(item, index) in option.material.properties"
            class="w-60"
          >
            <label for="name" class="block text-sm font-medium text-gray-700"
              >{{ item.name }}
            </label>
            <div class="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
              <input
                :type="item.type ?? 'text'"
                :name="item.name"
                :value="item.value"
                disabled
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Enter Quantity"
              />
            </div>
          </div>
          <div v-if="option?.iconUrl || option?.workType?.iconUrl">
            <img
              :src="option.iconUrl ? option.iconUrl : option.workType.iconUrl"
              class="w-52 flex-none rounded-lg bg-gray-800 object-contain"
              alt="profile-image"
            />
          </div>
          <div v-show="editModal" class="my-4 flex items-center text-sm text-gray-500">
            <CurrencyDollarIcon
              class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
              aria-hidden="true"
            />
            <span class="truncate flex items-center"
              >Cost Per Unit :
              <input
                :disabled="!option.workType?.material?.cost?.variableCost"
                type="number"
                placeholder="cost"
                v-model="inputObject[selectedOptionsIndex].costPerUnit"
                @input="handleCostPerUnit"
                class="bg-transparent mx-3 py-0 w-16 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
              />
            </span>
          </div>

          <div class="col-span-2 grid grid-cols-2 gap-4">
            <div class="flex items-end" v-if="inputObject[selectedOptionsIndex].totals">
              <p class="text-base text-gray-500 bottom-0 left-0">
                Total Quantity :
                <span class="text-gray-900">{{
                  inputObject[selectedOptionsIndex].totals.quantity
                }}</span>
              </p>
            </div>
            <div class="flex items-end" v-if="inputObject[selectedOptionsIndex].totals">
              <p class="text-base text-gray-500 bottom-0 left-0">
                Total Cost :
                <span class="text-gray-900">{{
                  inputObject[selectedOptionsIndex].totals.cost
                }}</span>
              </p>
            </div>
          </div>

          <div class="flex justify-end mx-5 py-4">
            <button
              type="button"
              class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
              @click="handleCancel"
            >
              Cancel
            </button>
            <button
              v-show="!isFirstStep"
              class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-primary-500 px-4 py-2 text-sm font-medium text-white hover:bg-primary-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
              @click="handlePrevious(selectedOption[step])"
            >
              Previous
            </button>
            <button
              :disabled="disableSubmit"
              :class="{ 'cursor-not-allowed': disableSubmit }"
              v-show="isLastStep"
              type="submit"
              class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
              @click="handleSubmit"
            >
              Submit
            </button>
            <button
              v-show="!isLastStep"
              class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
              @click="handleNext(selectedOption[step])"
            >
              Next
            </button>
          </div>
        </div>

        <nav class="flex items-center justify-center" aria-label="Progress">
          <p class="text-sm font-medium">
            Step {{ step + 1 }} of
            {{ totalSteps }}
          </p>
        </nav>
      </div>
    </Modal>
    <div class="my-12 px-4 bg-primary-50 py-6 border rounded-lg border pb-6 border-gray-200">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Construction Materials</h1>
        </div>
        <div class="flex justify-center items-center mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <div class="w-80 col-span-1">
            <Dropdown
              :options="rowFields"
              :resetDropdown="resetDropdown"
              @update-selected="updateSelectedOptions"
            />
          </div>
          <div class="ml-4">
            <button
              @click="handleAddRow"
              :disabled="!selectedOption"
              type="button"
              class="inline-flex items-center rounded-full border border-transparent bg-primary-600 p-2 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <PlusIconMini class="h-4 w-4 hover:cursor-pointer" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-hidden bg-white shadow sm:rounded-md mt-8">
        <div v-if="rowsData.length == 0" class="relative flex justify-center">
          <div class="h-52 relative flex justify-center items-center">
            <p class="px-2 text-lg text-gray-700">No data to display</p>
          </div>
        </div>
        <ul v-else role="list" class="divide-y divide-gray-200">
          <li v-for="item in props.rowsData" :key="item.id">
            <a class="block hover:bg-primary-50">
              <div class="flex items-center px-4 py-4 sm:px-6">
                <div class="flex min-w-0 flex-1 items-center">
                  <div class="flex-shrink-0">
                    <CogIcon class="h-6 w-6 text-gray-500" aria-hidden="true" />
                  </div>
                  <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                    <div>
                      <p class="truncate text-sm font-medium text-primary-600">
                        {{ item.workType.name }}
                      </p>
                      <div class="mt-2 flex items-center text-sm text-gray-500">
                        <CurrencyDollarIcon
                          class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                          aria-hidden="true"
                        />
                        <span class="truncate flex items-center"
                          >Cost Per Unit :
                          <span class="font-semibold px-3">
                            {{ formatNumber(item.costPerUnit) }}</span
                          ></span
                        >
                      </div>
                    </div>
                    <div class="hidden md:block">
                      <div>
                        <p class="text-sm text-gray-500">
                          Unit of Measurement :
                          <span class="font-semibold px-1">{{
                            item.workType.material?.cost?.unitOfMeasurement
                          }}</span>
                        </p>

                        <p
                          v-if="item.isComplete"
                          class="mt-2 flex items-center text-sm text-gray-500"
                        >
                          Quantity :<span class="font-semibold px-1"> {{ item.quantity }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="hidden md:block">
                      <div
                        v-if="!item.isComplete"
                        class="mt-2 flex items-center w-28 text-failure-700 bg-failure-50 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                      >
                        Action Required
                      </div>

                      <div v-else>
                        <p class="mt-2 flex items-center text-sm text-gray-500">
                          <CurrencyDollarIcon
                            class="mr-1.5 h-5 w-5 flex-shrink-0"
                            aria-hidden="true"
                          />
                          Total Cost :<span class="font-semibold px-1">
                            {{ item.totalCost == null ? 0 : formatNumber(item.totalCost) }}</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="px-1">
                    <PencilSquareIcon
                      @click="handleEditRow(item)"
                      class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="px-1">
                    <TrashIcon
                      @click="$emit('removeRow', name, 'Delete Material', item)"
                      class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useMutation } from '@vue/apollo-composable'

import {
  PencilSquareIcon,
  TrashIcon,
  CogIcon,
  CurrencyDollarIcon,
  InformationCircleIcon,
} from '@heroicons/vue/20/solid'

import { PlusIcon as PlusIconMini } from '@heroicons/vue/20/solid'
import Modal from '@/components/layout/Modal.vue'
import Dropdown from './Dropdown.vue'
import CALCULATE_TOTALS from '../../graphql/mutations/calculations/calculateTotals.gql'
import {
  extractDensityAndGid,
  formatNumber,
  isValidNumberValue,
  debounce,
} from '../../utils/utility_methods'

const emit = defineEmits([
  'addRow',
  'inputChange',
  'saveData',
  'editRow',
  'removeRow',
  'costUpdated',
])
const props = defineProps({
  rowFields: {
    type: Array,
    default: [],
  },
  rowsData: {
    type: Array,
    default: [],
  },
  initialData: {
    type: Object,
    default: {},
  },
  name: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
})

const showModal = ref(false)
const selectedOption = ref(null)
const selectedData = ref(null)
const calculations = ref([])
const resetDropdown = ref(false)
const editModal = ref(false)
const step = ref(0)
const inputObject = ref([])
const disableSubmit = ref(true)
const valuesUpdated = ref(false)
const isFirstStep = computed(() => step.value === 0)
const isLastStep = computed(() => step.value + 1 === selectedOption.value.length)
const totalSteps = computed(() => selectedOption.value.length)
const hasEmptyInput = computed(() => {
  return inputObject.value[0].properties.some((item) => !item.value)
})
const editValuesFilled = computed(() => inputObject.value[0]?.properties.every((v) => v.value))
const editPayloadValues = computed(() =>
  inputObject.value[0]?.properties.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {})
)
const { mutate: totalsCalculate } = useMutation(CALCULATE_TOTALS)

watch(
  () => [editPayloadValues.value, valuesUpdated.value],
  async ([payloadValue, valueUpdated]) => {
    if (editValuesFilled.value && editModal.value && valueUpdated) {
      debouncedCalculateTotals(payloadValue, 0)
    }
    if (valuesUpdated.value) {
      valuesUpdated.value = false
    }
  },
  { deep: true }
)

watch(selectedOption, (value) => {
  selectedData.value = value.map((item, index) => ({
    ...item,
    status: index === step.value ? 'current' : 'upcoming',
  }))
  if (!editModal.value) {
    inputObject.value = value.map((item) => {
      return {
        workTypeId: item.id,
        name: item.name,
        formulaBindings: item.formulaBindings,
        costPerUnit: item.costPerUnit ? item.costPerUnit : item.material?.cost?.costPerUnit,
        unitOfMeasurement: item.unitOfMeasurement
          ? item.unitOfMeasurement
          : item.material?.cost?.unitOfMeasurement,
        properties: item.properties.map((property) => ({
          id: property.id,
          name: property.name,
          key: extractDensityAndGid(item.formulaBindings).find((i) => {
            return (
              i.name == property.name.toLowerCase() ||
              (property.name == 'square footage' && i.name == 'area')
            )
          })?.gid,
          value:
            property.name == 'smr'
              ? 's'
              : property.name == 'number of coats'
              ? '1'
              : property.value,
          unitOfMeasurement: property.name == 'smr' ? 's' : property.unitOfMeasurement,
        })),
        totals: null,
      }
    })
  }
})

const handleAddRow = () => {
  showModal.value = true
  resetDropdown.value = !resetDropdown.value
}

const handleEditRow = (rowItem) => {
  editModal.value = true
  inputObject.value = [
    {
      workTypeId: rowItem.workType.id,
      costPerUnit: rowItem.costPerUnit,
      unitOfMeasurement: rowItem?.workType?.material?.cost?.unitOfMeasurement,
      formulaBindings: rowItem.workType.formulaBindings,
      properties: rowItem.proposalsWorkProperties.map((property) => ({
        id: property.id,
        name: property.name,
        key: extractDensityAndGid(rowItem.workType.formulaBindings).find((i) => {
          return (
            i.name == property.name.toLowerCase() ||
            (property.name == 'square footage' && i.name == 'area')
          )
        })?.gid,
        value: property.value,
        unitOfMeasurement: property.unitOfMeasurement,
      })),
      totals: { quantity: rowItem.quantity, cost: rowItem.totalCost },
    },
  ]
  showModal.value = true
  selectedOption.value = [
    {
      ...rowItem,
      properties: rowItem.proposalsWorkProperties,
    },
  ]
  valuesUpdated.value = true
}

const handleChange = async (e, smrInput, index) => {
  if (smrInput) {
    inputObject.value[index].properties.find((item) => item.name == 'smr').value = e.target.value
    inputObject.value[index].properties.find((item) => item.name == 'smr').unitOfMeasurement =
      e.target.value
  }
  valuesUpdated.value = true
  let payloadValues = inputObject.value[index].properties.reduce(
    (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
    {}
  )
  if (!hasEmptyInput.value && !editModal.value) {
    debouncedCalculateTotals(payloadValues, index)
    disableSubmit.value = false
  } else if (editModal.value && editValuesFilled.value) {
    disableSubmit.value = false
  }
}

const handleSubmit = () => {
  if (editModal.value) {
    emit('editRow', props.name, {
      quantity: inputObject.value[0].totals.quantity,
      costPerUnit: inputObject.value[0].costPerUnit,
      property: inputObject.value[0].properties.map((item) => ({ id: item.id, value: item.value })),
    })
  } else {
    emit('addRow', props.name, inputObject.value)
  }
  calculations.value = []
  valuesUpdated.value = false
}

const handleNext = () => {
  selectedData.value[step.value].status = 'complete'
  selectedData.value[step.value + 1].status = 'current'
  step.value++
}

const handlePrevious = () => {
  selectedData.value[step.value].status = 'upcoming'
  selectedData.value[step.value - 1].status = 'current'
  step.value--
}

const handleCancel = () => {
  step.value = 0
  selectedOption.value = []
  showModal.value = false
  inputObject.value = []
  calculations.value = []
  editModal.value = false
  valuesUpdated.value = false
}

const updateSelectedOptions = (options) => {
  selectedOption.value = options
}

const calculateTotals = async (payloadValues, index) => {
  const totals = await totalsCalculate({
    input: {
      input: {
        constructionWorkId: inputObject.value[index].workTypeId,
        properties: inputObject.value[index].properties.map((item) => ({
          key: item.key,
          value: payloadValues[item.name].replace(/[^\d.smr]/g, ''),
          unitOfMeasurement: item.unitOfMeasurement,
        })),
        materialCost: inputObject.value[index].costPerUnit,
      },
    },
  })
  inputObject.value[index].totals = {
    quantity: totals.data.calculateTotals.result.quantity,
    cost: totals.data.calculateTotals.result.cost,
  }
}

const debouncedCalculateTotals = debounce(async (payloadValues, index) => {
  await calculateTotals(payloadValues, index)
}, 700)

const formatWithCommas = (optionIndex, propertyIndex) => {
  let value = inputObject.value[optionIndex].properties[propertyIndex].value
  value = value.toString().replace(/[^\d.]/g, '') // Remove non-digit characters
  if (value != null) {
    if (isValidNumberValue(value)) {
      inputObject.value[optionIndex].properties[propertyIndex].value =
        parseFloat(value).toLocaleString()
    } else {
      inputObject.value[optionIndex].properties[propertyIndex].value = value
    }
  }
}

const handleCostPerUnit = () => {
  disableSubmit.value = false
  valuesUpdated.value = true
}
</script>
<style>
.scroll {
  overflow-x: auto;
}
.scroll::-webkit-scrollbar {
  display: none;
}
</style>
